import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

import Team from "../components/sections/team"
import Cta from "../components/ui/cta"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="CEO amp team: Raconteur, The Times and freuds"
        description="Amp brings together three media powerhouses. Strategy and creation expertise of Raconteur, distribution of The Times and media training by freuds."
      />
      <Team />
      <Cta />
    </Layout>
  )
}

export default IndexPage
