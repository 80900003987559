import React from "react"
import styled from "styled-components"

import Container, { Row } from "../layout/container"

import Arrow from "../../images/arrow.svg"
import Logo from "../../images/logo.svg"
import Raconteur from "../../images/brands/raconteur.svg"
import Times from "../../images/brands/times.svg"
import Freuds from "../../images/brands/freuds.svg"

const Section = styled.section`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.light};
  padding: 5rem 0;
  margin-top: -1rem;

  svg {
    margin-top: 1rem;
    margin-bottom: 1rem;
    fill: ${props => props.theme.colors.light};
    width: 100%;
    height: 100px;
  }
`

const Headline = styled.div`
  h1 {
    margin: 0;
    color: ${props => props.theme.colors.light};
    font-size: 30px;
    line-height: 1;
    text-align: center;
    @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 40px;
      margin-bottom: 3rem;
    }
    @media screen and (min-width: ${props => props.theme.breakpoints.desktop}) {
      font-size: 50px;
    }
  }

  p {
    margin: 0 0 0 2rem;
    max-width: 450px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
    flex-direction: column;

    h2 {
      width: auto;
      text-align: center;
      border-right: none;
      font-size: 35px;
      padding: 0;
    }
    p {
      margin: 1rem 0 0 0;
      text-align: center;
    }
  }
`

const Combine = styled(Arrow)`
  display: block;
  width: 100%;
  height: auto !important;
  margin: 3rem 0 !important;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;

  .logo {
    display: block;
    fill: ${props => props.theme.colors.light};
    height: auto;
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
  }

  div {
    width: 50%;
    @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
      width: 100%;
    }

    &:first-of-type,
    &:last-of-type {
      width: 20%;
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        width: 50%;
        margin: 0 auto;
      }
    }
    &:first-of-type {
      @media screen and (max-width: ${props => props.theme.breakpoints.phone}) {
        display: none;
      }
    }
  }
`

const Third = styled.div`
  width: 100% !important;
  flex-grow: 1 !important;

  svg {
    max-width: 80%;
    display: block;
    margin: 3rem auto 0;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.tablet}) {
    width: 33%;
    &:first-of-type {
      margin-right: 3rem;
    }
    &:nth-of-type(2) {
      border-left: solid 2px #00b1b2;
      border-right: solid 2px #00b1b2;
      padding: 0 3rem;
    }
    &:last-of-type {
      margin-left: 3rem;
    }
  }
`

const Team = () => {
  return (
    <React.Fragment>
      <Section id="team">
        <Container>
          <Headline>
            <h1>
              We’ve brought three media powerhouses together to ensure your
              voice gets heard
            </h1>
          </Headline>
        </Container>
        <Container>
          <Row>
            <Third>
              <Raconteur />
              <h3>
                UK’s leading publisher of business content for the c-suite
              </h3>
              <p>
                The content strategy and creation experts with a background in
                creating business thought leaders.
                <br />
                Raconteur will help you define your identity, shape your
                narrative and create your thought leading content.
              </p>
            </Third>
            <Third>
              <Times />
              <h3>UK’s leading business paper</h3>
              <p>
                Premier publisher with the unrivalled distribution. The Times
                will provide you with credibility and access to a
                highly-engaged, senior business audience.
              </p>
            </Third>
            <Third>
              <Freuds />
              <h3>UK’s largest independent PR firm</h3>
              <p>
                Public relations experts with the knowhow to guide you through
                the media landscape. freuds will stress-test your story and
                equip you with the skills to handle anything the media throws at
                you.
              </p>
            </Third>
          </Row>
          <Combine />
        </Container>
        <Wrapper>
          <div />
          <div>
            <Logo />
          </div>
          <div />
        </Wrapper>
      </Section>
    </React.Fragment>
  )
}

export default Team
